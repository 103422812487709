import React from 'react'
import classNames from 'classnames'
import AltPageLayout from 'Components/layout/AltPageLayout'
import BasicSection from 'Components/sections/BasicSection'
import BasicSectionTexts from 'Components/sections/BasicSectionTexts'
import Row from 'Components/Row/Row'
import Input, { INPUT_TYPES } from 'Components/inputs/Input/Input'
import { fadeUp } from 'Utils/animations'
import Touchable from 'Components/touchables/Touchable'
import Text from 'Components/texts/Text'
import Button from 'Components/touchables/Button'
import Menu from 'Components/layout/Menu'
import { useContactForm, useMaybeChangeLanguageOnInit } from 'Utils/common'
import type { PageDataType } from 'Typings/api'
import styles from './ContactsPage.module.scss'

const ContactsPage = (props: PageDataType) => {
  const { path, data } = props

  const {
    t,
    isSmallDevice,
    form,
    formSubmit,
    onChangeName,
    onChangeContact,
    onChangeText,
    isButtonDisabled,
    name,
    contact,
    text,
    isFetchingForm,
  } = useContactForm({ withCheckbox: false })

  const isInit = useMaybeChangeLanguageOnInit()

  if (!data || !isInit) {
    return null
  }

  return (
    <>
      <Menu />
      <AltPageLayout
        seo={data.seo}
        path={path}
        title={data.heading}
        subTitle={data.sub_heading}
        firstSectionHasLabel={Boolean(data.form_label)}
      >
        <BasicSection
          index={0}
          label={data.form_label}
          labelVariant="md"
          className={styles.formSection}
          labelTextClassName={styles.formLabelText}
        >
          <div className={styles.form}>
            <Row className={styles.formFirstRow}>
              <Input
                className={styles.formInput}
                label={form.name}
                value={name}
                onChange={onChangeName}
                {...fadeUp({ index: 1 })}
              />
              <Input
                className={styles.formInput}
                label={form.contact}
                value={contact}
                onChange={onChangeContact}
                {...fadeUp({ index: 2 })}
              />
            </Row>
            <Input
              className={classNames([styles.formInput, styles.formTextArea])}
              type={INPUT_TYPES.textArea}
              label={data.form_text_placeholder}
              value={text}
              onChange={onChangeText}
              rows={isSmallDevice ? 12 : 6}
              {...fadeUp({ index: 3 })}
            />
          </div>
          <Row className={styles.formFooter}>
            <div className={styles.formFooterEmail}>
              <Touchable href={`mailto:${t('email')}`}>
                <Text variant="sm" {...fadeUp({ index: isSmallDevice ? 3 : 4 })}>
                  {t('email')}
                </Text>
              </Touchable>
              <Text variant="xs" {...fadeUp({ index: isSmallDevice ? 2 : 5 })}>
                {t('emailLabel')}
              </Text>
            </div>
            <div
              className={styles.formFooterButtonContainer}
              {...fadeUp({ index: isSmallDevice ? 1 : 6 })}
            >
              <Button
                className={styles.formFooterButton}
                isFetching={isFetchingForm}
                title={form.submit}
                disabled={isButtonDisabled}
                onClick={formSubmit}
              />
            </div>
          </Row>
        </BasicSection>
        <BasicSectionTexts
          data={{
            ...data.texts,
            type: 'texts',
            id: 1,
            // @ts-ignore: How to fix this?
            styling: { background: 'Light' },
          }}
          index={1}
          subtractIndentsTop
        />
      </AltPageLayout>
    </>
  )
}

export default ContactsPage
